// extracted by mini-css-extract-plugin
export var Header = "Header-module--Header--P8nKI";
export var Light = "Header-module--Light--awWPB";
export var Menu = "Header-module--Menu--oG2JO";
export var Active = "Header-module--Active--+QTIC";
export var Logo = "Header-module--Logo--t6cP6";
export var Lang = "Header-module--Lang--cV--p";
export var BurgerLine = "Header-module--BurgerLine--BsjrJ";
export var DropDown = "Header-module--DropDown--kxXeP";
export var ActiveParent = "Header-module--ActiveParent--yjGLr";
export var Overlay = "Header-module--Overlay--su5n4";
export var OpenMenu = "Header-module--OpenMenu--zc9eq";
export var LinkWrap = "Header-module--LinkWrap--CAdAi";
export var IsParent = "Header-module--IsParent--8O6-k";
export var Burger = "Header-module--Burger--t2ezQ";
export var arrowLeft = "Header-module--arrowLeft--hB-Wl";
export var arrowRight = "Header-module--arrowRight--fGs3G";