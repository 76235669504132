import React from 'react'

function IconLogo({ className }) {
    return (
        <svg className={className} width={157} height={44} viewBox='0 0 157 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1500_31870)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M106.561 15.719C106.561 12.6158 108.747 11.6758 111.561 11.6758H114.091C116.884 11.6758 119.292 12.5653 119.292 15.719V15.9211H117.4V15.719C117.4 13.6974 115.376 13.4144 113.777 13.4144H111.561C109.81 13.4144 108.464 13.7075 108.464 15.719C108.464 17.7305 110.488 17.6193 112.056 17.6193H114.08C116.863 17.6193 119.737 18.1954 119.737 21.6625C119.737 24.8869 117.582 25.7056 114.678 25.7056H111.257C108.413 25.7056 106.258 24.432 106.258 21.3289V21.1267H108.16V21.3289C108.16 23.5425 109.82 23.9469 111.753 23.9469H114.475C116.115 23.9469 117.724 23.5425 117.724 21.6119C117.724 19.6813 116.084 19.3679 114.364 19.3679H111.54C108.929 19.3679 106.541 18.8221 106.541 15.6785'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M75.7047 11.6836H75.0469V13.6445V17.5664V19.5173V23.7525V25.7438H76.9393H77.081H88.1723V23.7525H77.081V19.5173H87.7169V17.5664H77.081V13.6445H88.1723V11.6836H75.7047Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M133.319 25.7438H135.353V11.6836H133.319V17.5058H123.806V11.6836H121.762V25.7438H123.806V19.4971H133.319V25.7438Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M90.7344 11.1719L100.854 20.451L101.866 21.4618L102.18 21.7549L104.204 23.6249V26.2631L92.7786 15.7508V25.7779H90.7546L90.7344 11.1719ZM102.332 19.4705V11.6672H104.356V21.3102L102.332 19.4705Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M60.5708 19.5189V25.7454H58.5469V11.5943H66.916C68.3764 11.5045 69.8244 11.9106 71.0246 12.7466C71.7112 13.3087 72.1521 14.1155 72.254 14.9963C72.356 15.8772 72.1111 16.7632 71.5711 17.467C71.2899 17.8217 70.9471 18.123 70.5591 18.3565C71.9354 19.0236 72.1074 20.9542 72.1074 22.2784V25.705H70.1341V22.1773C70.1341 21.6517 70.0531 20.3579 69.6483 19.9839C69.1826 19.6464 68.6135 19.4819 68.0393 19.5189H60.5708ZM60.5708 13.4541V17.659H67.4118C68.1778 17.7153 68.9396 17.5046 69.5674 17.0627C69.8003 16.8843 69.9891 16.6548 70.1189 16.3918C70.2488 16.1289 70.3164 15.8397 70.3164 15.5465C70.3164 15.2533 70.2488 14.964 70.1189 14.7011C69.9891 14.4382 69.8003 14.2087 69.5674 14.0303C68.9547 13.6039 68.2186 13.3907 67.4726 13.4238H60.5708V13.4541Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M45.3667 13.1289H38.3537L22.668 22.1958V26.2288L45.3667 13.1289Z'
                    fill='#EC2027'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M45.3667 21.9959L41.8551 19.9844L22.668 31.0728V35.1362L45.3667 21.9959Z'
                    fill='#EC2027'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M45.3667 30.8996L41.8551 28.8477L22.668 39.9664V43.9995L45.3667 30.8996Z'
                    fill='#EC2027'
                />
                <path fillRule='evenodd' clipRule='evenodd' d='M22.669 22.1968L18.8438 19.9832L22.669 17.7695V22.1968Z' fill='#EC2027' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M22.668 12.9267V8.86328L11.1719 15.5447L14.6531 17.5662L22.668 12.9267Z'
                    fill='#EC2027'
                />
                <path fillRule='evenodd' clipRule='evenodd' d='M0 13.1289H6.98267L22.6684 22.1958V26.2288L0 13.1289Z' fill='#931C1F' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M0 21.9959L3.50146 19.9844L22.6684 31.0728V35.1362L0 21.9959Z'
                    fill='#931C1F'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M0 30.8996L3.50146 28.8477L22.6684 39.9664V43.9995L0 30.8996Z'
                    fill='#931C1F'
                />
                <path fillRule='evenodd' clipRule='evenodd' d='M22.668 22.1968L26.5135 19.9832L22.668 17.7695V22.1968Z' fill='#931C1F' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M22.668 12.9267V8.86328L34.1843 15.5447L30.6829 17.5662L22.668 12.9267Z'
                    fill='#931C1F'
                />
                <path fillRule='evenodd' clipRule='evenodd' d='M22.668 0L45.3667 13.1303H38.3537L22.668 4.03308V0Z' fill='#931C1F' />
                <path fillRule='evenodd' clipRule='evenodd' d='M22.6684 0L0 13.1303H6.98267L22.6684 4.03308V0Z' fill='#EC2027' />
                <path d='M140.361 11.6836H138.316V25.7337H140.361V11.6836Z' fill='black' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M143.285 11.1719L153.456 20.451L154.468 21.4618L154.781 21.7549L156.805 23.6249V26.2631L145.329 15.7508V25.7779H143.305L143.285 11.1719ZM154.832 19.4705V11.6672H156.856V21.3102L154.832 19.4705Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M62.1473 30.8672H62.7849V36.2648H62.1473V35.3551H62.0866C61.945 35.6566 61.7158 35.9085 61.4287 36.078C61.1417 36.2475 60.8103 36.3267 60.4776 36.3053C60.2281 36.312 59.9802 36.2644 59.751 36.1658C59.5218 36.0673 59.3168 35.9201 59.1503 35.7345C58.9837 35.5489 58.8596 35.3293 58.7864 35.091C58.7133 34.8527 58.693 34.6014 58.7268 34.3544V30.8672H59.3644V34.3241C59.3503 34.5045 59.374 34.6859 59.4339 34.8566C59.4939 35.0274 59.5887 35.1838 59.7125 35.3159C59.8362 35.4481 59.9861 35.5531 60.1527 35.6242C60.3193 35.6953 60.4988 35.7311 60.68 35.7291C60.8744 35.7291 61.0669 35.6906 61.2462 35.6157C61.4256 35.5407 61.5883 35.431 61.7248 35.2927C61.8613 35.1545 61.969 34.9905 62.0415 34.8103C62.114 34.6302 62.15 34.4374 62.1473 34.2433V30.8672Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M65.2257 30.8686H65.8227V31.6975H65.9037C66.0319 31.418 66.2391 31.182 66.4998 31.0186C66.7605 30.8552 67.0633 30.7714 67.3711 30.7777H67.6848V31.4145C67.6848 31.4145 67.4824 31.4145 67.3104 31.4145C67.1216 31.4034 66.9326 31.4311 66.7549 31.4957C66.5772 31.5604 66.4147 31.6606 66.2772 31.7903C66.1398 31.92 66.0304 32.0765 65.9557 32.25C65.881 32.4235 65.8427 32.6104 65.843 32.7993V36.2663H65.1953V30.8686H65.2257Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M71.9114 35.7937C72.9234 35.7937 73.5811 34.7829 73.5811 33.5801C73.5811 32.3772 73.0043 31.3866 71.9114 31.3866C70.8184 31.3866 70.2821 32.3065 70.2821 33.5801C70.2821 34.8537 70.8792 35.7937 71.9114 35.7937ZM69.6445 29.082H70.2821V31.9022C70.408 31.5623 70.6407 31.2723 70.9453 31.0756C71.2499 30.8788 71.6102 30.7858 71.9721 30.8105C73.2978 30.8105 74.1883 31.9325 74.1883 33.6104C74.1883 35.2883 73.2978 36.4002 71.9721 36.4002C71.6117 36.4136 71.2567 36.3094 70.9609 36.1033C70.6651 35.8972 70.4446 35.6005 70.3327 35.258H70.2517V36.2688H69.6445V29.082Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M79.5641 34.2446V33.4663C79.1207 33.6749 78.6374 33.7852 78.1474 33.7898C77.2366 33.901 76.7104 34.1638 76.7104 34.8006C76.7104 35.4374 77.2366 35.8114 77.9551 35.8114C78.1648 35.8182 78.3738 35.7826 78.5694 35.7068C78.765 35.631 78.9433 35.5165 79.0936 35.3702C79.2439 35.2239 79.363 35.0488 79.4438 34.8554C79.5247 34.662 79.5656 34.4542 79.5641 34.2446ZM76.1133 34.8006C76.1133 33.7291 76.9735 33.3855 78.1372 33.2439C79.0278 33.1227 79.5439 33.1227 79.5439 32.7183V32.6071C79.5561 32.4416 79.531 32.2755 79.4706 32.1209C79.4101 31.9663 79.3157 31.8272 79.1944 31.7138C79.0731 31.6004 78.9279 31.5156 78.7695 31.4655C78.6111 31.4154 78.4435 31.4014 78.2789 31.4245C77.9767 31.4066 77.6761 31.4799 77.4162 31.6349C77.1563 31.7899 76.9491 32.0195 76.8217 32.2938L76.2145 32.0916C76.3824 31.7034 76.668 31.3776 77.0311 31.16C77.3942 30.9424 77.8165 30.844 78.2384 30.8787C78.4879 30.8495 78.7407 30.876 78.9787 30.9561C79.2167 31.0361 79.434 31.1679 79.615 31.3419C79.7959 31.5159 79.936 31.7277 80.0252 31.9622C80.1144 32.1967 80.1505 32.448 80.1309 32.6981V36.2662H79.5641V35.4071C79.4146 35.7196 79.1769 35.9818 78.8803 36.1614C78.5837 36.3409 78.241 36.4301 77.8944 36.4179C77.668 36.4303 77.4415 36.3963 77.2289 36.3179C77.0162 36.2395 76.8218 36.1185 76.6578 35.9622C76.4938 35.806 76.3635 35.6178 76.2751 35.4093C76.1867 35.2008 76.1419 34.9765 76.1436 34.75'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M83.2665 36.2653H82.6289V30.8677H83.2361V31.6965H83.2968C83.4388 31.404 83.6648 31.1603 83.9459 30.9965C84.2271 30.8327 84.5507 30.7562 84.8755 30.7767C85.13 30.7642 85.3841 30.8077 85.6199 30.904C85.8557 31.0003 86.0674 31.1472 86.2402 31.3342C86.4129 31.5212 86.5425 31.7439 86.6197 31.9864C86.6969 32.2289 86.7198 32.4853 86.687 32.7377V36.2653H86.0798V32.768C86.0985 32.5821 86.0774 32.3944 86.0178 32.2173C85.9582 32.0403 85.8615 31.8779 85.7342 31.7411C85.6068 31.6044 85.4517 31.4963 85.2792 31.4241C85.1067 31.3519 84.9208 31.3173 84.7338 31.3225C84.5338 31.3148 84.3344 31.3494 84.1488 31.4242C83.9632 31.499 83.7955 31.6122 83.6569 31.7564C83.5182 31.9006 83.4117 32.0726 83.3445 32.2608C83.2772 32.4491 83.2506 32.6495 83.2665 32.8488V36.2653Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M94.7003 30.8662H95.3277V36.2639H94.7003V30.8662ZM94.5586 29.5117C94.5586 29.4193 94.5862 29.3291 94.6378 29.2524C94.6894 29.1757 94.7627 29.1162 94.8484 29.0813C94.934 29.0464 95.0281 29.0378 95.1187 29.0566C95.2093 29.0754 95.2922 29.1206 95.3569 29.1867C95.4216 29.2527 95.4651 29.3365 95.4819 29.4274C95.4987 29.5182 95.488 29.612 95.4512 29.6968C95.4144 29.7815 95.3532 29.8534 95.2753 29.9033C95.1974 29.9532 95.1065 29.9787 95.014 29.9767C94.8947 29.9671 94.7829 29.9148 94.6992 29.8293C94.6155 29.7439 94.5655 29.6311 94.5586 29.5117Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M98.4048 36.2649H97.7773V30.8673H98.3744V31.6961H98.4351C98.5782 31.4045 98.8044 31.1615 99.0853 30.9979C99.3662 30.8342 99.6892 30.7571 100.014 30.7763C100.268 30.7638 100.522 30.8073 100.758 30.9036C100.994 31 101.206 31.1468 101.378 31.3338C101.551 31.5208 101.681 31.7435 101.758 31.986C101.835 32.2285 101.858 32.4849 101.825 32.7373V36.2649H101.198V32.7676C101.217 32.5809 101.195 32.3922 101.135 32.2144C101.075 32.0366 100.977 31.8738 100.849 31.7368C100.72 31.5999 100.564 31.492 100.39 31.4205C100.217 31.3489 100.03 31.3154 99.8418 31.3221C99.6422 31.316 99.4435 31.3517 99.2587 31.4271C99.0738 31.5024 98.9068 31.6157 98.7685 31.7595C98.6301 31.9034 98.5236 32.0746 98.4557 32.2622C98.3879 32.4498 98.3602 32.6495 98.3744 32.8484V36.2649H98.4048Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M106.279 36.2547H105.621L103.668 30.8672H104.356L105.965 35.4158H106.016L107.625 30.8672H108.293L106.279 36.2547Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M113.779 33.2354C113.798 33.0053 113.771 32.7737 113.698 32.5546C113.625 32.3354 113.509 32.1333 113.355 31.9604C113.202 31.7875 113.015 31.6474 112.806 31.5487C112.597 31.4499 112.371 31.3944 112.14 31.3857C111.671 31.4143 111.231 31.6227 110.912 31.9673C110.594 32.312 110.421 32.7663 110.429 33.2354H113.779ZM109.731 33.6398C109.731 31.9922 110.682 30.8398 112.089 30.8398C113.273 30.8398 114.366 31.6788 114.366 33.4881V33.8116H110.318C110.293 34.0655 110.321 34.3219 110.4 34.5644C110.479 34.807 110.608 35.0304 110.778 35.2205C110.949 35.4106 111.157 35.5633 111.389 35.6688C111.622 35.7742 111.874 35.8302 112.13 35.8332C112.435 35.8474 112.738 35.7703 112.999 35.6119C113.26 35.4534 113.469 35.2208 113.597 34.9437L114.194 35.1458C114.024 35.5363 113.74 35.8662 113.379 36.0918C113.017 36.3174 112.596 36.4281 112.17 36.4093C111.818 36.4058 111.471 36.3287 111.151 36.183C110.831 36.0373 110.545 35.8262 110.311 35.5634C110.078 35.3006 109.902 34.9919 109.795 34.6571C109.688 34.3223 109.652 33.9689 109.691 33.6195'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M119.617 32.2249C119.543 31.9582 119.378 31.7257 119.151 31.5671C118.924 31.4085 118.649 31.3337 118.372 31.3556C117.654 31.3556 117.138 31.7296 117.138 32.2754C117.138 32.8212 117.462 33.0335 118.15 33.175L118.949 33.3772C119.84 33.6097 120.305 34.0645 120.305 34.7822C120.305 35.702 119.496 36.3388 118.281 36.3388C117.842 36.3918 117.398 36.2793 117.037 36.0233C116.676 35.7673 116.423 35.3861 116.328 34.954L116.925 34.8125C116.98 35.1218 117.151 35.3986 117.403 35.5864C117.655 35.7743 117.969 35.859 118.281 35.8233C119.111 35.8233 119.627 35.419 119.627 34.8125C119.627 34.206 119.344 34.0645 118.767 33.9533L117.876 33.7512C116.925 33.5187 116.5 33.0335 116.5 32.3057C116.5 31.4162 117.279 30.8098 118.362 30.8098C118.765 30.7789 119.166 30.8892 119.497 31.1216C119.827 31.354 120.067 31.694 120.174 32.0834L119.617 32.2249Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M124.595 31.4169H123.442V34.8435C123.442 35.5308 123.847 35.7026 124.251 35.7026C124.375 35.7232 124.502 35.7232 124.626 35.7026L124.768 36.2788C124.592 36.3416 124.407 36.369 124.221 36.3596C124.04 36.3681 123.859 36.339 123.689 36.2742C123.52 36.2094 123.366 36.1103 123.236 35.9831C123.107 35.8559 123.006 35.7033 122.938 35.535C122.871 35.3667 122.839 35.1863 122.845 35.0052V31.3865H122.035V30.8407H122.845V29.5469H123.472V30.8407H124.595V31.4169Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M126.77 30.8683H127.367V31.6972H127.427C127.545 31.4181 127.746 31.1824 128.004 31.023C128.261 30.8636 128.562 30.7883 128.864 30.8077C129.185 30.7953 129.502 30.887 129.766 31.0692C130.031 31.2514 130.229 31.5143 130.332 31.8185H130.382C130.504 31.5045 130.723 31.2373 131.006 31.0556C131.29 30.8739 131.625 30.7871 131.961 30.8077C132.973 30.8077 133.57 31.4445 133.57 32.6473V36.266H132.943V32.6473C132.965 32.4839 132.952 32.3174 132.902 32.1599C132.853 32.0025 132.769 31.8581 132.656 31.7371C132.544 31.6161 132.406 31.5216 132.252 31.4605C132.099 31.3994 131.934 31.3732 131.769 31.3838C131.592 31.3775 131.415 31.4087 131.251 31.4754C131.087 31.5421 130.939 31.6427 130.817 31.7707C130.694 31.8986 130.6 32.051 130.541 32.2178C130.482 32.3845 130.459 32.5619 130.473 32.7383V36.266H129.836V32.6271C129.852 32.4699 129.834 32.3111 129.783 32.1614C129.732 32.0117 129.65 31.8747 129.542 31.7596C129.433 31.6446 129.301 31.5542 129.155 31.4946C129.008 31.435 128.85 31.4075 128.692 31.4141C128.511 31.4177 128.332 31.4583 128.167 31.5335C128.002 31.6087 127.855 31.7169 127.733 31.8515C127.612 31.986 127.519 32.144 127.462 32.3157C127.404 32.4873 127.382 32.669 127.397 32.8495V36.266H126.77V30.8683Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M139.779 33.2354C139.798 33.0053 139.771 32.7737 139.698 32.5546C139.625 32.3354 139.508 32.1333 139.355 31.9604C139.202 31.7875 139.015 31.6474 138.806 31.5487C138.597 31.4499 138.37 31.3944 138.139 31.3857C137.668 31.4093 137.224 31.6158 136.903 31.9613C136.581 32.3068 136.408 32.764 136.419 33.2354H139.779ZM135.781 33.6398C135.781 31.9922 136.733 30.8398 138.139 30.8398C139.323 30.8398 140.406 31.6788 140.406 33.4881V33.8116H136.419C136.394 34.0655 136.422 34.3219 136.501 34.5644C136.58 34.807 136.709 35.0304 136.879 35.2205C137.05 35.4106 137.258 35.5633 137.49 35.6688C137.723 35.7742 137.975 35.8302 138.23 35.8332C138.535 35.8495 138.837 35.7732 139.097 35.6144C139.357 35.4556 139.563 35.2217 139.688 34.9437L140.295 35.1458C140.125 35.5363 139.841 35.8662 139.48 36.0918C139.118 36.3174 138.697 36.4281 138.271 36.4093C137.913 36.4145 137.558 36.3434 137.23 36.2009C136.902 36.0584 136.608 35.8476 136.367 35.5827C136.127 35.3178 135.946 35.0048 135.836 34.6646C135.727 34.3243 135.691 33.9646 135.731 33.6094'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M143.145 36.2653H142.508V30.8677H143.115V31.6965H143.176C143.318 31.404 143.544 31.1603 143.825 30.9965C144.106 30.8327 144.43 30.7562 144.754 30.7767C145.009 30.7642 145.263 30.8077 145.499 30.904C145.735 31.0003 145.946 31.1472 146.119 31.3342C146.292 31.5212 146.421 31.7439 146.499 31.9864C146.576 32.2289 146.599 32.4853 146.566 32.7377V36.2653H145.928V32.768C145.947 32.5821 145.926 32.3944 145.866 32.2173C145.807 32.0403 145.71 31.8779 145.583 31.7411C145.455 31.6044 145.3 31.4963 145.128 31.4241C144.955 31.3519 144.769 31.3173 144.582 31.3225C144.382 31.3148 144.183 31.3494 143.997 31.4242C143.812 31.499 143.644 31.6122 143.505 31.7564C143.367 31.9006 143.26 32.0726 143.193 32.2608C143.126 32.4491 143.099 32.6495 143.115 32.8488V36.2653H143.145Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M151.162 31.4169H150.008V34.8435C150.008 35.5308 150.413 35.7026 150.818 35.7026C150.942 35.7232 151.068 35.7232 151.192 35.7026L151.334 36.2788C151.159 36.3426 150.973 36.3701 150.787 36.3596C150.606 36.3681 150.425 36.339 150.256 36.2742C150.086 36.2094 149.932 36.1103 149.803 35.9831C149.673 35.8559 149.572 35.7033 149.505 35.535C149.437 35.3667 149.405 35.1863 149.411 35.0052V31.3865H148.602V30.8407H149.411V29.5469H150.039V30.8407H151.192V31.4169H151.162Z'
                    fill='black'
                />
                <path
                    opacity='0.4'
                    d='M156.302 32.2243C156.23 31.9585 156.067 31.7262 155.842 31.5675C155.616 31.4088 155.343 31.3336 155.068 31.355C154.349 31.355 153.833 31.729 153.833 32.2748C153.833 32.8207 154.147 33.0329 154.845 33.1744L155.644 33.3766C156.535 33.6091 157.001 34.0639 157.001 34.7816C157.001 35.7014 156.191 36.3382 154.977 36.3382C154.537 36.3936 154.092 36.2821 153.73 36.0257C153.368 35.7692 153.116 35.3866 153.023 34.9534L153.621 34.8119C153.675 35.1212 153.846 35.398 154.098 35.5858C154.35 35.7737 154.664 35.8584 154.977 35.8227C155.806 35.8227 156.323 35.4184 156.323 34.8119C156.323 34.2055 156.039 34.0639 155.462 33.9528L154.572 33.7506C153.621 33.5181 153.195 33.0329 153.195 32.3052C153.195 31.4157 153.965 30.8092 155.058 30.8092C155.46 30.7803 155.86 30.8913 156.191 31.1234C156.521 31.3555 156.76 31.6944 156.869 32.0828L156.302 32.2243Z'
                    fill='black'
                />
            </g>
            <defs>
                <clipPath id='clip0_1500_31870'>
                    <rect width={157} height={44} fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconLogo
